import Tooltip from "~/components/ui/tooltip";
import { Icon } from "~/components/ui/icons";

export const InfoIcon = ({ tooltip }: { tooltip: React.ReactNode }) => {
	return (
		<Tooltip label={tooltip}>
			<div className="hidden lg:block">
				<Icon name="info" width="16" height="16" color="secondary" />
			</div>
		</Tooltip>
	);
};
